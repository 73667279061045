var exports = {};
exports = decimal; // Check if the given character code, or the character code at the first
// character, is decimal.

function decimal(character) {
  var code = typeof character === "string" ? character.charCodeAt(0) : character;
  return code >= 48 && code <= 57;
  /* 0-9 */
}

export default exports;